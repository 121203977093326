import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ErrorBlock from "../components/ErrorBlock";
import LoadingButton from "../components/LoadingButton";
import LOGO from "../components/Logo";

import { postJsonData } from "../utils";
import { CLIENT_ID } from "../../constants";
import Urls from "../../UrlConfig";

export default ({ from_route }) => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [api_state, setApiState] = useState({ loading: false, errors: {} });

  const is_user_logged = useSelector((store) => store.auth.logged);
  const is_staff = useSelector((store) => store.auth.is_staff);

  const loginUser = (event) => {
    event.preventDefault();

    if (api_state.loading) return;

    postJsonData(
      Urls.apiAuthLogin(),
      { username, password, client_id: CLIENT_ID },
      (res) => {
        // console.log(res)
        setApiState({ loading: false, errors: {} });
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: res,
        });
      },
      (err) => {
        console.log(err);
        setApiState({ loading: false, errors: err.error_data });
      }
    );

    setApiState({ loading: true, errors: {} });
  };

  if (is_user_logged) {
    // only admin  user can login
    if (is_staff) {
      if (from_route) {
        return <Redirect to={from_route} />;
      } else {
        return <Redirect to={Urls.getHomePage()} />;
      }
    } else {
      return <Redirect to={Urls.getUnauthorized()} />;
    }
  }

  return (
    <form onSubmit={loginUser}>
      <LOGO />

      <div className="form-content-margin">
        <div className="title accent-text bold">Sign In</div>
        <div className="sub-title accent-text">
          Welcome to Logistics, enter details and press on Sign In to continue
        </div>

        <div className="wrapper">
          <input
            name="username"
            placeholder="Enter Username"
            type="text"
            className="auth-input"
            onChange={({ target }) => setUsername(target.value)}
            value={username}
          />
          <ErrorBlock
            className="error-block"
            field="username"
            error={api_state.errors}
          />
          <ErrorBlock
            className="error-block"
            field="email"
            error={api_state.errors}
          />
        </div>

        <div className="wrapper">
          <input
            name="password"
            placeholder="Enter Password"
            type="password"
            className="auth-input"
            onChange={({ target }) => setPassword(target.value)}
            value={password}
          />
          <ErrorBlock
            className="error-block"
            field="password"
            error={api_state.errors}
          />
          <ErrorBlock
            className="error-block"
            field="__all__"
            error={api_state.errors}
          />
        </div>
        <div className="tiny-url accent-text">
          Forget Password ?
          <Link className="bold text-link" to={""}>
            {" "}
            Click here
          </Link>
        </div>
        <div className="btn-wrapper">
          <LoadingButton
            className="btn primary-btn"
            onClick={loginUser}
            loading={api_state.loading}
            label="Sign In"
          />
        </div>
      </div>
    </form>
  );
};
